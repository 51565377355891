import {
  Text,
  Box,
  Container,
  Divider,
  Heading,
  SimpleGrid,
  HStack,
} from "@chakra-ui/react";
import { graphql, Link, PageProps } from "gatsby";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Card from "src/atoms/Card";
import Layout from "src/components/Layout";
import Markdown from "src/components/Markdown";
import Picture from "src/components/Picture";
import StructuredText from "src/components/StructuredText";
import { formatDate } from "src/lib/formatDate";
import getBlogPath from "src/lib/getBlogPath";
import getExcerpt from "src/lib/getExcerpt";

const Blogs: FC<PageProps<Gatsby.BlogsQuery>> = ({
  data: { datoCmsBlogsPage, allDatoCmsBlogArticle, allDatoCmsMember },
}) => {
  if (!datoCmsBlogsPage || !allDatoCmsBlogArticle || !allDatoCmsMember)
    throw Error();

  const blogPosts = allDatoCmsBlogArticle.nodes;

  const byAuthor = blogPosts.reduce((acc, blogPost) => {
    const authorId = blogPost.author!.id!;

    return {
      ...acc,
      [authorId]: [...(acc[authorId] ?? []), blogPost],
    };
  }, {} as { [authorId: string]: any });

  const getAuthor = (authorId: string) =>
    allDatoCmsMember.nodes.find((member) => member.id === authorId)!;

  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: "description",
            content: getExcerpt(datoCmsBlogsPage.body),
          },
        ]}
        title={datoCmsBlogsPage.title}
      />

      <Container maxW="container.xl">
        <Card mb={6} size="lg">
          <Heading as="h1" mb={8} size="xl">
            {datoCmsBlogsPage.title}
          </Heading>

          <Markdown>
            <StructuredText data={datoCmsBlogsPage.body} />
          </Markdown>
        </Card>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          {Object.entries<Gatsby.BlogArticleFragment[]>(byAuthor).map(
            ([authorId, posts]) => {
              const author = getAuthor(authorId);
              const lastPost = posts[0];

              return (
                <Card as={Link} key={authorId} to={getBlogPath(authorId)}>
                  <HStack minH="60px" spacing={3}>
                    <Picture
                      borderRadius="full"
                      image={author.portrait?.blogCardThumbnail}
                    />

                    <Box>
                      <Text fontWeight="semibold" size="md">
                        {`Blog de ${author.firstName} ${author.lastName}`}
                      </Text>

                      <Text
                        color="gray.500"
                        fontSize="sm"
                        fontWeight="semibold"
                      >
                        {`${posts.length} article${
                          posts.length > 1 ? "s" : ""
                        } de blog`}
                      </Text>
                    </Box>
                  </HStack>

                  <Divider my={4} />

                  <Box>
                    <Heading as="h2" noOfLines={2} size="sm">
                      {lastPost.title}
                    </Heading>

                    <Text color="gray.500" fontSize="sm" mb={3}>
                      {`Le ${formatDate(lastPost.publicationDate!)}`}
                    </Text>

                    <Text fontSize="sm" noOfLines={4}>
                      {getExcerpt(lastPost.body, { length: 500 })}
                    </Text>
                  </Box>
                </Card>
              );
            }
          )}
        </SimpleGrid>
      </Container>
    </Layout>
  );
};

export default Blogs;

export const query = graphql`
  query Blogs {
    datoCmsBlogsPage {
      title
      body {
        value
      }
    }

    allDatoCmsBlogArticle(sort: { fields: publicationDate, order: DESC }) {
      nodes {
        ...BlogArticle
      }
    }

    allDatoCmsMember {
      nodes {
        ...Member
        portrait {
          blogCardThumbnail: gatsbyImageData(width: 60, aspectRatio: 1)
        }
      }
    }
  }
`;
